import React from "react"
import slugify from "slugify"
import { scrollElementIntoView } from "utils/scrollIntoView"
import { Button } from "@mostlycode/ui"
import styled from "styled-components"

const StyledNav = styled.nav`
  ul {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    width: 100%;

    li {
      a {
        display: block;
        padding: 1rem 2rem;
        font-family: ${(props) => props.theme.fontStacks.bodyBold};
        transition: color ease-out 0.2s;

        &:hover {
          color: ${(props) => props.theme.colors.primary};
          cursor: pointer;
        }
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        a {
          display: inline-block;
          padding: 1rem 2rem;
          font-size: 1.3rem;
        }
      }
    }
  `}
`

const StyledDownloadLink = styled.a`
  margin: 1rem;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 4.6rem;
  line-height: 1.5;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.4px;
  font-size: 1.7rem;
  font-family: ${(props) => props.theme.fontStacks.heading};
  padding: 1rem 2rem;
  display: inline-block;

  &.download {
    &:hover {
      color: ${(props) => props.theme.colors.black};
    }
  }
`

function NavigationList({ onPageNav, onPageNavHandler }) {
  if (!onPageNav) return null
  const navItems = onPageNav.map((n) => {
    const anchor = slugify(n.anchor, {
      replacement: "-",
      lower: true,
      strict: false,
      locale: "da",
    })
    return {
      title: n.anchor,
      anchor: `#${anchor}`,
    }
  })

  function handleOnClick() {
    if (onPageNavHandler) onPageNavHandler()
  }

  return (
    <StyledNav>
      <ul>
        {navItems.map((n, i) => {
          return (
            <li key={`on_page_nav_${i}`}>
              <a
                href={`/${n.anchor}`}
                onClick={(e) => {
                  scrollElementIntoView(e, n.anchor)
                  handleOnClick()
                }}
              >
                {n.title}
              </a>
            </li>
          )
        })}
        <li>
          <StyledDownloadLink
            id="download_samlehaefte"
            className="download"
            href="/wellness-low.pdf"
            target="_blank"
            rel="noreferrer noopener"
            forwardedAs="a"
            size="sm"
          >
            Download PDF
          </StyledDownloadLink>
        </li>
      </ul>
    </StyledNav>
  )
}

export default NavigationList
