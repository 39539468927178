import React from "react"
import { Link } from "gatsby"
import { Icon } from "@mostlycode/ui"
import styled from "styled-components"
import Headroom from "react-headroom"

const StyledHeader = styled.header`
  padding: 1rem 2rem;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .inner {
      width: 96rem;
      margin: 0 auto;
    }
  `}
`

const StyledHeadroom = styled(Headroom)`
  position: relative;
  z-index: 20000;
  background: ${(props) => props.theme.colors.white};
`

const StyledLogo = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    width: 150px;
    height: 63px;
  }
`

function Header({ children, ...props }) {
  return (
    <StyledHeadroom>
      <StyledHeader>
        <div className="inner">
          <StyledLogo to="/">
            <Icon icon="logo-row" />
          </StyledLogo>
          {children}
        </div>
      </StyledHeader>
    </StyledHeadroom>
  )
}

export default Header
