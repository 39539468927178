/**
 * SEO Meta Resolver
 */

const metaResolver = (meta, defaultMeta) => {
  // First, set meta data to data input to the SEO component
  const seo = { ...meta }

  // If the value of a key is null, replace it with the default meta key value
  Object.keys(seo).forEach(val => {
    if (seo[val] === null) {
      seo[val] = defaultMeta[`default${val.charAt(0).toUpperCase()}${val.slice(1)}`]
    }
  })

  // I18N
  // If a 'lang' is defined, add two new properties to the object for the 'og:locale'-meta and <html> 'lang' property.
  if (seo['lang'] !== null) {
    // Get the two parts of the language
    const lang = seo['lang'].split('-')
    // og:lang takes a locale in the language_TERRITORY format, e.g. en_US
    // Sometimes lang only contains one part. In that case, use the language as the territory too.
    seo['ogLang'] = lang[1] ? `${lang[0]}_${lang[1].toUpperCase()}` : `${lang[0]}_${lang[0].toUpperCase()}`
    seo['siteLAng'] = lang[0] // lang-property only takes two lang characters
  }

  // IMAGES
  // Resolve the 'main_image'-property for the seo-object, for use twitter:image property, that doesn't support arrays.
  // Also used as <meta name="image" /> value.
  if (typeof seo['images'] === 'object' && seo['images'].length) {
    seo['mainImage'] = seo['images'][0]
  } else if (typeof seo['images'] === 'object') {
    // It's a single object
    seo['mainImage'] = seo['images']
  } else {
    throw new Error('Error: Meta image property is not an array of objects or a single object')
  }

  // TITLE
  // Adjust title with prepended and appended meta texts
  seo['title'] = [seo['metaTitlePrefix'], seo['title'], seo['metaTitleAppendix']].filter(Boolean).join(" | ")

  return seo
}

export { metaResolver }
