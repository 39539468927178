import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import { metaResolver } from "utils/seoResolver"
import Facebook from "./Facebook"
import Twitter from "./Twitter"

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({
  title,
  description,
  author,
  slug,
  lang,
  images,
  siteUrl,
  metaTitlePrefix,
  metaTitleAppendix,
  logo,
  facebook,
  twitter,
  hideFromGoogle,
  defaultMeta,
  ...props
}) => {
  const seo = metaResolver(
    {
      title,
      description,
      author,
      slug,
      lang,
      images,
      siteUrl,
      metaTitlePrefix,
      metaTitleAppendix,
      logo,
      facebook,
      twitter,
    },
    defaultMeta
  )

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={seo.siteLang} />
        <meta name="description" content={seo.description} />
        <meta
          name="image"
          content={`${seo.siteUrl}${seo.mainImage?.imageSharp?.childImageSharp?.main?.src}`}
        />
        <link rel="canonical" href={`${seo.siteUrl}${seo.slug}`} />
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5XB8LMM');
          `}
        </script>
        {hideFromGoogle && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
      <Facebook
        desc={seo.description}
        title={seo.title}
        type="website"
        url={`${seo.siteUrl}${seo.slug}`}
        locale={seo.ogLang}
        name={facebook}
      >
        {seo.images.length ? (
          seo.images.map(({ image, imageSharp }, idx) => {
            return [
              <meta
                key={`meta_img_${idx}`}
                property="og:image"
                content={`${seo.siteUrl}${imageSharp?.childImageSharp?.main?.src}`}
              />,
              <meta
                key={`meta_img_alt_${idx}`}
                property="og:image:alt"
                content={image?.alt}
              />,
            ]
          })
        ) : (
          <Helmet>
            <meta
              property="og:image"
              content={`${seo.siteUrl}${seo.images?.url}`}
            />
            <meta property="og:image:alt" content={`${seo.images?.alt}`} />
          </Helmet>
        )}
      </Facebook>
      <Twitter
        title={seo.title}
        siteUrl={seo.siteUrl}
        image={seo.mainImage}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default SEO

export const query = graphql`
  fragment SiteMetaFragment on SiteSiteMetadata {
    defaultTitle: title
    defaultDescription: description
    defaultAuthor: author
    defaultLang: lang
    defaultSiteUrl: siteUrl
    defaultMetaTitlePrefix: metaTitlePrefix
    defaultMetaTitleAppendix: metaTitleAppendix
    defaultLogo: logo
    defaultFacebook: facebook
    defaultTwitter: twitter
  }

  fragment PrismicSiteSettingsMetaFragment on PRISMIC_Site_settings {
    default_meta_title
    default_meta_description
    meta_title_prefix
    meta_title_appendix
    default_meta_image
    default_meta_imageSharp @include(if: $isProduction) {
      childImageSharp {
        id
        desktop: fixed(width: 1200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

SEO.defaultProps = {
  title: null,
  description: null,
  author: null,
  slug: null,
  lang: null,
  siteUrl: null,
  metaTitlePrefix: null,
  metaTitleAppendix: null,
  logo: null,
  facebook: null,
  twitter: null,
  images: [
    {
      url: "",
      alt: "",
    },
  ],
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  slug: PropTypes.string,
  lang: PropTypes.string,
  siteUrl: PropTypes.string,
  metaTitlePrefix: PropTypes.string,
  metaTitleAppendix: PropTypes.string,
  logo: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
}
