import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  @import url("//hello.myfonts.net/count/3c687f");
  body {
    color: ${(props) => props.theme.colors.grayDark};
    background: ${(props) => props.theme.colors.white};
    font-size: 1.7rem;
  }

  strong {
    font-size: 1em;
  }

  address {}

  article {}

  aside {}

  footer {}

  header {}

  main {}

  nav {}

  section {}

  img { width: 100%; display: block; }

  h1 {
    font-family: ${(props) => props.theme.fontStacks.heading};
  }

  h2 {
    font-size: 2.6rem;
    line-height: 3.8rem;
    font-family: ${(props) => props.theme.fontStacks.heading};
    font-weight: 500;
  }

  h3 {
    font-size: 2.2rem;
    line-height: 3.4rem;
    font-family: ${(props) => props.theme.fontStacks.heading};
  }

  p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    h2 {
      font-size: 2.8rem;
      line-height: 4.0rem;
    }
  `}

`

export { GlobalStyles }
