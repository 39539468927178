const colors = {
  primary: "#C49E94",
  primary_accent: "#CEC7C0",
  white: "#FFFFFF",
  black: "#312621",
  bilkablue: "#009fe3",
  foetexblue: "#131E39",
  gray_light: "#F5F5F5",
  true_black: "#000000"
}

export { colors }
