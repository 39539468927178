/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { GlobalStyles, FontFamilies } from "@mostlycode/ui/theme"
import { GlobalStyles as SiteGlobalStyles } from "theme/global"
import { siteTheme } from "theme"
import styled, { ThemeProvider } from "styled-components"
import { NavToggle, NavUntoggle } from "@mostlycode/ui"

import { NavigationContext, ViewportContext } from "context"
import { isViewport } from "helpers"
import useViewport from "hooks/useViewport"

import Header from "components/Header"
import Navigation from "components/Navigation"
import OffCanvasNav from "components/OffCanvasNav"
import Footer from "components/Footer"

const StyledNavToggle = styled(NavToggle)`
  border: none;

  &:focus {
    outline: none;
  }
`

const StyledNavUntoggle = styled(NavUntoggle)`
  border: none;

  &:focus {
    outline: none;
  }
`

const Layout = ({
  site_settings: { copyright_information },
  pageNav,
  children,
}) => {
  const [navToggled, setNavToggled] = useState(false)
  const viewport = useViewport()

  function handleNavToggle() {
    setNavToggled((prev) => !prev)
  }

  return (
    <ThemeProvider theme={siteTheme}>
      <FontFamilies />
      <GlobalStyles />
      <SiteGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        <NavigationContext.Provider value={navToggled}>
          <Header>
            {!isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
              <nav>
                <Navigation onPageNav={pageNav} />
              </nav>
            ) : (
              <nav>
                <StyledNavToggle onToggle={handleNavToggle} />
              </nav>
            )}
          </Header>
          <main>{children}</main>
          <Footer copyright_information={copyright_information} />
          {isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) && (
            <OffCanvasNav
              nav={
                <Navigation
                  onPageNav={pageNav}
                  onPageNavHandler={handleNavToggle}
                />
              }
            />
          )}
        </NavigationContext.Provider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
