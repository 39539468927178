import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { Icon } from "@mostlycode/ui"
import Legal from "./Legal"

const StyledFooter = styled.footer`
  background: ${(props) => props.theme.colors.primary_accent};
  padding: 5rem 2rem;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 100;
  h4 {
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 10rem 2rem;
  `}
`

const StyledLogo = styled(Link)`
  svg {
    height: 5.7rem;
    width: 22.8rem;
  }
`

function Footer({ copyright_information, children, ...props }) {
  return (
    <StyledFooter>
      <StyledLogo to={"/"}>
        <Icon icon="logo-row" title="Logo" />
      </StyledLogo>
      <Legal copyright_text={copyright_information} />
    </StyledFooter>
  )
}

export default Footer
