import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"

const StyledLegal = styled.div`
  margin-top: 4rem;
  text-align: center;

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  a {
    color: ${(props) => props.theme.colors.true_black};
  }

  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

function Legal({ copyright_text }) {
  return (
    <StyledLegal>
      <Content content={copyright_text} />
    </StyledLegal>
  )
}

export default Legal

Legal.defaultProps = {
  copyright_text: "All rights reserved.",
}

Legal.propTypes = {
  copyright_text: PropTypes.any,
}
