import { theme } from '@mostlycode/ui/theme'
import { colors } from './colors'
import { fonts, fontStacks } from './fonts'

const siteTheme = {
  ...theme,
  colors,
  fonts: fonts,
  fontStacks: fontStacks,
  baseBodyColor: `#2d2b2c`,
  linkColor: colors.primary,
  fontSizes: {
    p: "1.7",
    h1: "4.2",
    h2: "3.6",
    h3: "2.8",
    h4: "2.6",
    h5: "2.4",
    h6: "1.8",
  },
}

export { siteTheme }
