import { useState, useEffect } from 'react'

export default function useViewport() {
  const [viewport, setViewport] = useState(null)

  function handleViewportChange() {
    const vp = window.innerWidth
    if (vp < 599) {
      setViewport('PHONE_ONLY')
    } else if (vp >= 600 && vp < 900) {
      setViewport('TABLET_PORTRAIT_UP')
    } else if (vp >= 900 && vp < 1200) {
      setViewport('TABLET_LANDSCAPE_UP')
    } else {
      setViewport('DESKTOP')
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleViewportChange()
      window.addEventListener('resize', handleViewportChange, false)
    }
    return function cleanup() {
      window.removeEventListener('resize', handleViewportChange, false)
    }
  })

  return viewport
}
