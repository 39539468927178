const fonts = [
  {
    source: `static`,
    fonts: [
      {
        filename: `avenir`,
        title: `Avenir`,
        config: {
          weights: [`black`, `roman`],
        },
      },
    ],
  }
]

const fontStacks = {
  heading: `BlackerProCondDis-ExBdIt, serif`,
  medium: `BlackerProDis-Md, serif`,
  bodyBold: `'Avenir black', serif`,
  body: `'Avenir roman', sans-serif`,
}

export { fonts, fontStacks }
