const scrollElementIntoView = (event, id) => {
  if (typeof window !== `undefined`) {
    const elm = document.querySelector(id)
    if (elm) {
      if (event) event.preventDefault()
      elm.scrollIntoView({ behavior: "smooth" })
    }
  }
  return
}

export { scrollElementIntoView }
