import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { OffCanvas } from "@mostlycode/ui"
import { NavigationContext } from "context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"

const StyledOffCanvasNavigation = styled.div`
  width: 100vw;
  min-height: 20rem;
  display: flex;
  flex: 1 1 50%;
  justify-content: space-between;
  background: white;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
`

const StyledNavInner = styled.nav`
  // position: absolute;
  margin-top: 8.3rem;
  right: 2rem;
  left: 2rem;
`

function OffCanvasNav({ nav }) {
  const toggled = useContext(NavigationContext)
  useLockBodyScroll(toggled)
  return (
    <OffCanvas selector="#___offcanvas" show={toggled} fromTop>
      <StyledOffCanvasNavigation>
        <StyledNavInner>{nav}</StyledNavInner>
      </StyledOffCanvasNavigation>
    </OffCanvas>
  )
}

export default OffCanvasNav

OffCanvasNav.defaultProps = {
  children: null,
}

OffCanvasNav.propTypes = {
  navToggle: PropTypes.node,
  nav: PropTypes.node,
  children: PropTypes.node,
}
